import { DataObject } from 'o365.modules.DataObject.ts';
import FileUpload from 'o365.modules.FileUpload.ts';
import translate from 'o365.modules.translate.ts';
import {alert} from "o365.controls.alert.ts";
import SocketClient from 'o365.modules.SocketClient.ts';
import {SignalIRProcess} from 'o365.dataObject.extension.SignalRProcess.ts'

declare module "o365.modules.DataObject.ts" {
    interface DataObject {
        _importTemplate: DataObjectImportTemplate;
        importTemplate: DataObjectImportTemplate
    }
}

Object.defineProperty(DataObject.prototype, "importTemplate", {
    get: function importTemplate() {
        if (!this._importTemplate) this._importTemplate = new DataObjectImportTemplate(this);

        return this._importTemplate;
    }
});


class DataObjectImportTemplate{
    private _dataObject: DataObject;
    private _fileUpload: FileUpload;
    private _socket: SocketClient;

    beforeImport: Function | undefined;
    onCompleted: Function | undefined;
   // progress: ProgressHandler;
    importStates:ImportStates | undefined | null;
    completed:boolean = false;
    rowsFailed:number = 0;
    currentGroupKey:string = "";
    error:string|null = null;


    constructor(pDataObject: DataObject){
        this._dataObject = pDataObject;
        this._dataObject = pDataObject;
        this._fileUpload = new FileUpload({
           // url: `/api/workbook-import/chunkupload`,
            chunkUrl: `/api/workbook-import/chunkupload`,
            useChunks: true,
            viewName: this._dataObject.viewName
        });

   
   
        this._socket = new SocketClient((this._isBehindProxy()?'nt/':'')+`api/import/process/${this._dataObject.uniqueTable ?? this._dataObject.viewName}`);
    }

    cancel() {
        this.completed = false;

       // this.progress.message = `${translate('Canceling upload')}`;
        this._fileUpload.abort();
        this._socket.close();
    }

    private _getUplaodProgress(pFile:File, pUploaded:number){
        return Math.min(Math.round(pFile.size/pUploaded * 100),100);
    }

    async importData(pOptions:any){
        let vBeforeUploadParams: any;
        this.importStates = new ImportStates();
        this.completed = false;
        let total = 0;
        this.error = null;
        this.rowsFailed = 0;

        try{
            pOptions.onProgress = (pProgress:any)=>{
                this.importStates?.setMessage(`${this._getUplaodProgress(pOptions.file,pProgress.loaded)}%`);
            }



            this.importStates.setActiveByName("Uploading");
            const res = await this._fileUpload.upload(pOptions);
            this.importStates.setActiveByName("Parsing");
            const vOptions = {
                path:res.ResponseData.path,
                fileName:res.ResponseData.fileName,
                values:{} as any,
                fields:this._dataObject.fields.getAllFields(),
                uniqueField:this._dataObject.fields.uniqueField,
                viewName:this._dataObject.viewName,
                uniqueTable:this._dataObject.uniqueTable ?? this._dataObject.viewName,
                batch:pOptions.batch

            }

            if (pOptions.beforeImport) vBeforeUploadParams = pOptions.beforeImport.call(this,vOptions, ...arguments);
           
            if (vBeforeUploadParams && typeof vBeforeUploadParams == "object") {
                Object.keys(vBeforeUploadParams).forEach(key => {
                    vOptions.values[key] = vBeforeUploadParams[key];
                })

            }
            vBeforeUploadParams = this._dataObject.emit('BeforeImportTemplate', vOptions);
            if (vBeforeUploadParams && typeof vBeforeUploadParams == "object") {

                Object.keys(vBeforeUploadParams).forEach(key => {
                    vOptions.values[key] = vBeforeUploadParams[key];
                })
            }

            this._dataObject.fields.getAllFields().forEach(obj => {
                vOptions.values[obj.name] = obj.defaultValue;
            });

            if(this._dataObject.masterDetails.isSet){
                 vOptions.values = { ...vOptions.values, ...this._dataObject.masterDetails.getMasterDetailRowForInsert() };
            }

            let signal = null;
            signal = new SignalIRProcess();
            signal.executeSqlOverSignal(vOptions, (pProgres: any)=>{
                if(pProgres){
                    if(pProgres.error){
                        if (pOptions.onError) pOptions.onError.call(this,pProgres.error, ...arguments);
                        alert(pProgres.error);
                    }
                    if(pProgres.message){
                        this.importStates?.setMessage(translate(pProgres.message));

                    }
                    if(pProgres.total){

                        total = pProgres.total;
                        this.importStates?.setMessage(`0 ${translate("out of")} ${total}`);
                        
                    }
                    if(pProgres.current){
                        this.importStates?.setMessage(`${pProgres.current+1} ${translate("out of")} ${total}`);
                    }
                    if(pProgres.committing && this.importStates){
                    console.log(pProgres);
                        //this.currentGroupKey = pProgres.groupKey;
                        this.importStates.activeState.customMessage = ` ${total} ${translate('rows')} `;
                        if(pProgres.batch){
                            this.importStates.activeState.customMessage = ` ${total} ${translate('rows')} `;
                        }
                        this.importStates.setActiveByName("Committing");
                        if(pProgres.batch){
                            this.importStates.activeState.customMessage = ` ${translate('bulk')} ${pProgres.total} ${translate('rows')} `;
                        }

                    }
                    if(pProgres.hasOwnProperty('rowsSucceeded') && this.importStates){
                        if(pProgres.rowsFailed > 0){
                            this.rowsFailed = pProgres.rowsFailed
                        }                           
                        this.importStates.setMessage(`${pProgres.rowsSucceeded+(0??pProgres.rowsFailed)} ${translate("out of")} ${total}`); 
                    }
                    if(pProgres.EOS){
                        this.importStates.activeState.customMessage = ` ${total} ${translate('rows')} `;
                        this.importStates.activeState.completed = true;
                        this.importStates.resetActive();

                        if (pOptions.onCompleted) pOptions.onCompleted.call(this, res, ...arguments);
                        this._dataObject.load();
                        signal.stopConnection();
                    }
                }

            });

        

            // await this._socket.send(vOptions,(pProgres:any)=>{
            //     if(pProgres){
            //         if(pProgres.error){
            //             if (pOptions.onError) pOptions.onError.call(this,pProgres.error, ...arguments);
            //             alert(pProgres.error);
            //         }
            //         if(pProgres.message){
            //             this.importStates?.setMessage(translate(pProgres.message));

            //         }
            //         if(pProgres.total){

            //             total = pProgres.total;
            //             this.importStates?.setMessage(`0 ${translate("out of")} ${total}`);
                        
            //         }
            //         if(pProgres.current){
            //             this.importStates?.setMessage(`${pProgres.current+1} ${translate("out of")} ${total}`);
            //         }
            //         if(pProgres.committing && this.importStates){
            //         console.log(pProgres);
            //             //this.currentGroupKey = pProgres.groupKey;
            //             this.importStates.activeState.customMessage = ` ${total} ${translate('rows')} `;
            //             if(pProgres.batch){
            //                 this.importStates.activeState.customMessage = ` ${total} ${translate('rows')} `;
            //             }
            //             this.importStates.setActiveByName("Committing");
            //             if(pProgres.batch){
            //                 this.importStates.activeState.customMessage = ` ${translate('bulk')} ${pProgres.total} ${translate('rows')} `;
            //             }

            //         }
            //         if(pProgres.hasOwnProperty('rowsSucceeded') && this.importStates){
            //             if(pProgres.rowsFailed > 0){
            //                 this.rowsFailed = pProgres.rowsFailed
            //             }                           
            //             this.importStates.setMessage(`${pProgres.rowsSucceeded+(0??pProgres.rowsFailed)} ${translate("out of")} ${pProgres.rowsTotal}`); 
            //         }
            //     }
            // });
            //     this.importStates.activeState.customMessage = ` ${total} ${translate('rows')} `;
            //     this.importStates.activeState.completed = true;
            //     this.importStates.resetActive();

            //     if (pOptions.onCompleted) pOptions.onCompleted.call(this, res, ...arguments);
            //     this._dataObject.load();

        }catch (error:any) {
            if (pOptions.onError) pOptions.onError.call(this,error, ...arguments);
            alert(error);
        }
        this.completed = true;
      

      
    }
     private _isBehindProxy() : boolean{
        return (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content === 'true';
    }
}

class ImportStates{
    states:Array<ImportState> = [];
    activeState:ImportState;
    

    constructor(){
       
       
        this.states.push(new ImportState("Uploading"));
        this.states.push(new ImportState("Parsing"));
        this.states.push(new ImportState("Committing"));
    }
    isActive(pState:ImportState){
        if(!this.activeState) return false;
        if(this.activeState.name == pState.name){
            return true;
        }
        return false;
    }

    setMessage(pMessage:string){
        if(this.activeState)
            this.activeState.message = `${translate(this.activeState.name)} ${pMessage}`;
    }
    resetActive(){
        if(this.activeState)
        this.activeState.completed = true;
        this.activeState = null;
    }
    setActiveByName(pName:string){
        //if(this.states.find(x=>x.name == pName))
        if(this.activeState)
        this.activeState.completed = true;
        this.activeState = this.states.find((x:ImportState)=>x.name == pName);
    }
   
}
class ImportState{
    private _message:string = "";
    name:string;
    progress:number = 0;
    completed:boolean = false;
    customMessage:string = "";

    get message(){
        if(!this._message){
            return translate(this.name);
        }
        if(this.completed && !this.customMessage){
            return translate(this.name) + " " +translate("completed");
        }
         if(this.completed && this.customMessage){
            return translate(this.name) + " " + this.customMessage + translate("completed")+".";
        }

        return this._message;
    }

 

    set message(pMesssage:string){
        this._message = pMesssage;
    }
    

    constructor(pName:string){
        this.name = pName;
    }

   
}